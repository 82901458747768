<template>
  <div>
    <b-tabs
      pills
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-class="nav-left"
      nav-wrapper-class="col-md-3 col-12"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>
        <booking-request-detail-general
          :general="bookings"
        ></booking-request-detail-general>
      </b-tab>
      <div>
        <b-tab>
          <template #title>
            <feather-icon icon="BoxIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Propiedad</span>
          </template>
          <booking-request-detail-property
            :property="bookings.property"
          ></booking-request-detail-property>
        </b-tab>
        <!--
        <b-tab v-if="bookings.quotation.propertyquotation[0].warehouse != null">
          <template #title>
            <feather-icon icon="BoxIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Bodega</span>
          </template>
          <booking-request-detail-warehouse
            :warehouse="bookings.quotation.propertyquotation[0].warehouse"
          ></booking-request-detail-warehouse>
        </b-tab>
        -->
        <!--
        <b-tab
          v-if="bookings.quotation.propertyquotation[0].parkinglot != null"
        >
          <template #title>
            <feather-icon icon="TruckIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Estacionamiento</span>
          </template>
          <booking-request-detail-parkinglot
            :parkinglot="bookings.quotation.propertyquotation[0].parkinglot"
          ></booking-request-detail-parkinglot>
        </b-tab>
        -->
      </div>
    </b-tabs>
  </div>
</template>

<script>
import BookingRequestDetailGeneral from './details/request/BookingRequestDetailGeneral'
import BookingRequestDetailProperty from './details/request/BookingRequestDetailProperty'
import BookingRequestDetailParkinglot from './details/request/BookingRequestDetailParkinglot'
import BookingRequestDetailWarehouse from './details/request/BookingRequestDetailWarehouse'

export default {
  name: 'BookingRequestDetailList',
  props: ['bookings'],
  components: {
    BookingRequestDetailGeneral,
    BookingRequestDetailProperty,
    BookingRequestDetailParkinglot,
    BookingRequestDetailWarehouse
  },
  data () {
    return {}
  }
}
</script>
