<template>
  <div id="bookings-details">
    <bookings-requests-details-list :bookings="bookings"></bookings-requests-details-list>
  </div>
</template>

<script>
  import BookingsRequestsDetailsList from './components/BookingsRequestsDetailsList'

  export default{
    name: 'BookingsDetails',
    components: {BookingsRequestsDetailsList},
    props: ['bookings'],
    data() {
      return {
      }
    },
  }
</script>
