<template>
  <div>
    <b-card>
      <!-- <div class=""> -->
      <b-row class="mx-2 my-2">
        <b-col cols="6" md="4" class="my-1">
          <h4 class="font-weight-bolder">Nombre</h4>
          <h6 class="font-weight-normal">{{ general.contactBooking.name }}</h6>
        </b-col>
        <b-col cols="6" md="4" class="my-1">
          <h4 class="font-weight-bolder">Correo</h4>
          <h6 class="font-weight-normal">{{ general.contactBooking.email }}</h6>
        </b-col>
        <b-col cols="6" md="4" class="my-1">
          <h4 class="font-weight-bolder">R.U.N.</h4>
          <h6 class="font-weight-normal">{{ general.contactBooking.rut }}</h6>
        </b-col>
        <b-col cols="6" md="4" class="my-1">
          <h4 class="font-weight-bolder">Teléfono</h4>
          <h6 class="font-weight-normal">{{ general.contactBooking.phone }}</h6>
        </b-col>
        <b-col cols="6" md="4" class="my-1">
          <h4 class="font-weight-bolder">Estado</h4>
          <b-badge pill :variant="_getStatusColor(general.status)">
            {{ _getStatus(general.status) }}
          </b-badge>
        </b-col>
        <b-col cols="6" md="4" class="my-1">
          <h4 class="font-weight-bolder">Fecha</h4>
          <h6 class="font-weight-normal">
            {{ general.created_at | moment('DD-MM-YYYY') }}
          </h6>
        </b-col>
      </b-row>
      <!-- </div> -->
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'BookingsRequests',
  props: ['general'],
  data () {
    return {}
  },
  methods: {
    _getStatusColor (status) {
      switch (status) {
        case 'done':
          return 'light-success'
        case 'canceled':
          return 'light-danger'
        case 'pending':
          return 'light-warning'
      }
    },
    _getStatus (status) {
      switch (status) {
        case 'done':
          return 'Aprobada'
        case 'canceled':
          return 'Cancelada'
        case 'pending':
          return 'Pendiente'
        case 'validate':
          return 'Validada'
      }
    }
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
</style>
